import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { FaBook, FaFolder, FaGlobe, FaRegCommentAlt, FaTools } from "react-icons/fa";
import { HiBookOpen } from "react-icons/hi2";
import { LuRadioTower } from "react-icons/lu";
import { AiOutlineHistory } from "react-icons/ai";
import { SiGoogleclassroom } from "react-icons/si";
import { VscSymbolVariable } from "react-icons/vsc";
import { TbMilitaryRank } from "react-icons/tb";
import { FiDatabase } from "react-icons/fi";
import { IoCubeOutline, IoEarthSharp } from "react-icons/io5";
import { GoChecklist, GoChevronDown, GoChevronUp } from "react-icons/go";
import { TfiComments } from "react-icons/tfi";
import { CiPillsBottle1 } from "react-icons/ci";
import { MdOutlineContentPasteSearch } from "react-icons/md";
import { BsHighlights } from "react-icons/bs";
import { FaDatabase } from "react-icons/fa6";



const CDISCControl = ({ handleAddNewTab, checkActiveTab }) => {
  const [openTerminology, setOpenTerminology] = useState(false);
  const [openSTDM, setOpenSTDM] = useState(false);
  const [openVariable, setOpenVariable] = useState(false);
  const [openCDRGMetadata, setOpenCDRGMetadata] = useState(false);
  const [openSubjectDataDescription, setOpenSubjectDataDescription] =
    useState(false);
  return (
    <>
      <span
        className={`CP ps-3 small ${openTerminology && "selectedMenu"}`}
        onClick={() => setOpenTerminology(!openTerminology)}
      >
        <div className="hstack justify-content-between w-100">
          <span>
            <FaBook className="ps-1 me-2" style={{ fontSize: 17 }} />
            Terminology
          </span>
          {openTerminology ? <GoChevronUp /> : <GoChevronDown />}
        </div>
      </span>
      <Collapse in={openTerminology}>
        <div id="example-collapse-text">
          <span
            className={`CP ps-4 small py-1 ${
              checkActiveTab("TerminologyData") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                `Terminology`,
                "fa-solid fa-folder",
                "TerminologyData"
              );
            }}
          >
            <HiBookOpen className="ps-1 me-2" style={{ fontSize: 20 }} />{" "}
            Terminology
          </span>
          <span
            className={`CP ps-4 small py-1 ${
              checkActiveTab("STDMTerminologyData") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                `SDTM Terminology`,
                "fa-solid fa-folder",
                "STDMTerminologyData"
              );
            }}
          >
            <FaGlobe className="ps-1 me-2" style={{ fontSize: 18 }} /> SDTM
            Terminology
          </span>
        </div>
      </Collapse>
      <span
        className={`CP ps-3 small ${openSTDM && "selectedMenu"}`}
        onClick={() => setOpenSTDM(!openSTDM)}
      >
        <div className="hstack justify-content-between w-100">
          <span>
            <LuRadioTower
              className="ps-1 me-1"
              style={{ fontSize: 18, marginLeft: 1 }}
            />{" "}
            STDM
          </span>
          {openSTDM ? <GoChevronUp /> : <GoChevronDown />}
        </div>
      </span>
      <Collapse in={openSTDM}>
        <div id="example-collapse-text">
          <span
            className={`CP ps-4 small py-1 ${
              checkActiveTab("STDMVersionData") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                `Version`,
                "fa-solid fa-folder",
                "STDMVersionData"
              );
            }}
          >
            <AiOutlineHistory className="ps-2 me-2" style={{ fontSize: 25 }} />
            Version
          </span>
          <span
            className={`CP ps-4 small py-1 ${
              checkActiveTab("STDMClassData") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(`Class`, "fa-solid fa-folder", "STDMClassData");
            }}
          >
            <SiGoogleclassroom
              className="ps-2 me-2"
              style={{ fontSize: 25, marginLeft: 2 }}
            />
            Class
          </span>
          <span
            className={`CP ps-4 small py-1 ${
              checkActiveTab("STDMDomainData") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(`Domain`, "fa-solid fa-folder", "STDMDomainData");
            }}
          >
            <IoEarthSharp
              className="ps-2 me-2"
              style={{ fontSize: 25, marginLeft: 2 }}
            />
            Domain
          </span>
          <span
            className={`CP ps-4 small ${openVariable && "selectedMenu"}`}
            onClick={() => setOpenVariable(!openVariable)}
          >
            <div className="hstack justify-content-between w-100">
              <span>
                <IoCubeOutline
                  className="ps-2 me-2"
                  style={{ fontSize: 25, marginLeft: 2 }}
                />
                Variable
              </span>
              {openVariable ? <GoChevronUp /> : <GoChevronDown />}
            </div>
          </span>
          <Collapse in={openVariable}>
            <div id="example-collapse-text">
              <span
                className={`CP ps-4 small py-2 ps-5 ${
                  checkActiveTab("STDMMethodData") && "selectedMenu"
                }`}
                onClick={() => {
                  handleAddNewTab(
                    `Method`,
                    "fa-solid fa-folder",
                    "STDMMethodData"
                  );
                }}
              >
                <FaTools
                  className="ps-1 me-2"
                  style={{ fontSize: 19, marginLeft: 1 }}
                />
                Method
              </span>
              <span
                className={`CP ps-4 small py-2 ps-5 ${
                  checkActiveTab("STDMCommentData") && "selectedMenu"
                }`}
                onClick={() => {
                  handleAddNewTab(
                    `Comment`,
                    "fa-solid fa-folder",
                    "STDMCommentData"
                  );
                }}
              >
                <FaRegCommentAlt
                  className="ps-1 me-2"
                  style={{ fontSize: 19, marginLeft: 1 }}
                />
                Comment
              </span>
              <span
                className={`CP ps-4 small ps-5 py-2 ${
                  checkActiveTab("STDMVariableData") && "selectedMenu"
                }`}
                onClick={() => {
                  handleAddNewTab(
                    `Variable`,
                    "fa-solid fa-folder",
                    "STDMVariableData"
                  );
                }}
              >
                <VscSymbolVariable
                  className="ps-1 me-2"
                  style={{ fontSize: 22, marginLeft: 1 }}
                />{" "}
                Variable
              </span>
              <span
                className={`CP ps-4 small ps-5 py-2 ${
                  checkActiveTab("STDMValueLavelData") && "selectedMenu"
                }`}
                onClick={() => {
                  handleAddNewTab(
                    `Value Level`,
                    "fa-solid fa-folder",
                    "STDMValueLavelData"
                  );
                }}
              >
                <TbMilitaryRank
                  className="ps-1 me-2"
                  style={{ fontSize: 22, marginLeft: 1 }}
                />
                Value Level
              </span>
            </div>
          </Collapse>
        </div>
      </Collapse>
      <span
        className={`CP ps-3 small ${openCDRGMetadata && "selectedMenu"}`}
        onClick={() => setOpenCDRGMetadata(!openCDRGMetadata)}
      >
        <div className="hstack justify-content-between w-100">
          <span>
            <LuRadioTower
              className="ps-1 me-1"
              style={{ fontSize: 18, marginLeft: 1 }}
            />
            CDRG Metadata
          </span>
          {openCDRGMetadata ? <GoChevronUp /> : <GoChevronDown />}
        </div>
      </span>
      <Collapse in={openCDRGMetadata}>
        <div id="example-collapse-text">
          <span
            className={`CP ps-4 small py-1 ${
              checkActiveTab("CDRGAcronymsData") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                `Acronyms Metadata`,
                "fa-solid fa-folder",
                "CDRGAcronymsData"
              );
            }}
          >
            <TfiComments className="ps-2 me-2" style={{ fontSize: 20 }} />
            Acronyms Metadata
          </span>
          <span
            className={`CP ps-4 small py-1 ${
              checkActiveTab("CDRGTrialDesignDataset") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                `Trial Design Datasets`,
                "fa-solid fa-folder",
                "CDRGTrialDesignDataset"
              );
            }}
          >
            <CiPillsBottle1
              className="ps-2 me-2"
              style={{ fontSize: 20, marginLeft: 2 }}
            />
            Trial Design Datasets
          </span>

          <span
            className={`CP ps-4 small ${
              openSubjectDataDescription && "selectedMenu"
            }`}
            onClick={() =>
              setOpenSubjectDataDescription(!openSubjectDataDescription)
            }
          >
            <div className="hstack justify-content-between w-100">
              <span>
                <FaFolder
                  className="ps-2 me-2"
                  style={{ fontSize: 20, marginLeft: 2 }}
                />
                Subject Data Description
              </span>
              {openSubjectDataDescription ? <GoChevronUp /> : <GoChevronDown />}
            </div>
          </span>
          <Collapse in={openSubjectDataDescription}>
            <div id="example-collapse-text">
              <span
                className={`CP ps-4 small py-2 ps-5 ${
                  checkActiveTab("descriptionOverview") && "selectedMenu"
                }`}
                onClick={() => {
                  handleAddNewTab(
                    `Overview`,
                    "fa-solid fa-folder",
                    "descriptionOverview"
                  );
                }}
              >
                <MdOutlineContentPasteSearch
                  className="ps-1 me-2"
                  style={{ fontSize: 19, marginLeft: 1 }}
                />
                Overview
              </span>
              <span
                className={`CP ps-4 small py-2 ps-5 ${
                  checkActiveTab("annotatedCRFs") && "selectedMenu"
                }`}
                onClick={() => {
                  handleAddNewTab(
                    `Annotated CRFs`,
                    "fa-solid fa-folder",
                    "annotatedCRFs"
                  );
                }}
              >
                <BsHighlights
                  className="ps-1 me-2"
                  style={{ fontSize: 19, marginLeft: 1 }}
                />
                Annotated CRFs
              </span>
            </div>
          </Collapse>
          <span
            className={`CP ps-4 small py-1 ${
              checkActiveTab("CDRGDataConformance") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                `Data Conformance Inputs`,
                "fa-solid fa-folder",
                "CDRGDataConformance"
              );
            }}
          >
            <FaDatabase
              className="ps-2 me-2"
              style={{ fontSize: 18, marginLeft: 2 }}
            />
            Data Conformance Inputs
          </span>
          <span
            className={`CP ps-4 small py-1 ${
              checkActiveTab("CDRGIssueSummary") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                `Issue Summary`,
                "fa-solid fa-folder",
                "CDRGIssueSummary"
              );
            }}
          >
            <GoChecklist
              className="ps-2 me-2"
              style={{ fontSize: 20, marginLeft: 2 }}
            />
            Issue Summary
          </span>
        </div>
      </Collapse>
      <span
        className={`CP ps-3 small py-2 ${
          checkActiveTab("STDMDataTemplateData") && "selectedMenu"
        }`}
        onClick={() => {
          handleAddNewTab(
            `Data Template`,
            "fa-solid fa-folder",
            "STDMDataTemplateData"
          );
        }}
      >
        <FiDatabase
          className="ps-1 me-2"
          style={{ fontSize: 18, marginLeft: 2 }}
        />{" "}
        Data Template
      </span>
    </>
  );
};

export default CDISCControl;
