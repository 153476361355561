import React from 'react';
import { Form } from 'react-bootstrap';
import { HiMiniXMark } from 'react-icons/hi2';
import { ContextMenuTrigger } from 'react-contextmenu';
import { VscCloseAll } from 'react-icons/vsc';
import { LuDelete } from 'react-icons/lu';
import { MdOutlineDelete } from 'react-icons/md';
import { TiPin } from 'react-icons/ti';
import { IoIosArrowDropleft, IoIosArrowDropright, IoMdCloseCircleOutline } from 'react-icons/io';
import RightClick from '../../LeftComponent/RightClick';
import { calculateDuration } from '../../../utils/universalFunction';

// const ItemType = 'TAB';

const DraggableTab = ({
    item,
    index,
    // handleMoveTab,
    handleActiveTab,
    handleDeleteTab,
    handleCloseOtherTab,
    handleCloseRightTabs,
    handleCloseLeftTabs,
    handleDeleteAllTab,
    handleMoveTabLeft,
    handleMoveTabRight,
}) => {
    // const ref = useRef(null);
    // const [isDragging, setIsDragging] = useState(false);

    // const [, drop] = useDrop({
    //     accept: ItemType,
    //     hover: (draggedItem) => {
    //         if (draggedItem.index !== index) {
    //             handleMoveTab(draggedItem.index, index);
    //             draggedItem.index = index;
    //         }
    //     },
    //     collect: (monitor) => {
    //         setIsDragging(monitor.isOver());
    //     },
    // });

    // const [{ isDraggingItem }, drag] = useDrag({
    //     type: ItemType,
    //     item: { index },
    //     collect: (monitor) => ({
    //         isDraggingItem: monitor.isDragging(),
    //     }),
    // });

    // drag(drop(ref));

    return (
        <div
            // ref={ref}
            className={`tablist cursor-pointer ${item?.status}`}
            style={{
                background: "var(--resizeBg)",
                borderRadius: "3px 3px 0 0",
                // opacity: isDraggingItem ? 0.5 : 1,
                transition: 'transform 0.2s ease',
            }}
        >
            <ContextMenuTrigger
                id={item?.edcTabId + "uniqueId"}
                collect={() => ({ id: item?.edcTabId })}
            // disable={isDragging}
            >
                <div
                    key={index}
                    className={`hstack ps-2 pe-1 tablist ${item?.status === "active" && "activee"}`}
                    style={{ padding: 4.6, borderRadius: item?.status === "active" && "3px 3px 0 0", }}
                >
                    <Form.Label
                        onClick={() => handleActiveTab(item?.edcTabId)}
                        title={`Tab Name: ${item?.edcTabName
                            }\nOpened Time: ${calculateDuration(item?.openDT)}`}
                        className={`m-0 hstack cursor-pointer me-2 ${item?.status === "active" ? "text-white" : "text-muted"
                            }`}
                        // style={{ width: 110 }}
                    >
                        <i className={`${item?.icon} me-2`}></i>
                        <span className='cursor-pointer text-truncate' style={{ marginBottom: -2 }}>{item?.edcTabName}</span>
                    </Form.Label>
                    <HiMiniXMark
                        onClick={() => handleDeleteTab(item?.edcTabId)}
                        className={`rounded-circle ${item?.status === "active" ? "text-white" : "text-muted"}`}
                        style={{ width: 15, height: 15 }}
                        title="Close Tab"
                    />
                </div>
            </ContextMenuTrigger>
            <RightClick
                ContextId={item?.edcTabId + "uniqueId"}
                Menu1
                MenuName1={"Pin"}
                icons1={<TiPin className="text-white" />}
                className1="text-white"
                Menu2
                MenuName2={"Move Left"}
                icons2={<IoIosArrowDropleft className="text-white" />}
                handleClick2={() => handleMoveTabLeft(index)}
                className2="text-white"
                Menu3
                MenuName3={"Move Right"}
                icons3={<IoIosArrowDropright className="text-white" />}
                handleClick3={() => handleMoveTabRight(index)}
                className3="text-white"
                Menu4
                MenuName4={"Close Tab"}
                icons4={<IoMdCloseCircleOutline className="text-white" />}
                handleClick4={() => handleDeleteTab(item?.edcTabId)}
                className4="text-white"
                Menu5
                MenuName5={"Close Other Tab"}
                icons5={<VscCloseAll className="text-white" />}
                handleClick5={() => handleCloseOtherTab(item)}
                className5="text-white"
                Menu6
                MenuName6={"Close Right Tab"}
                icons6={
                    <LuDelete
                        className="text-white"
                        style={{ transform: "rotate(180deg)" }}
                    />
                }
                handleClick6={() => handleCloseRightTabs(index)}
                className6="text-white"
                Menu7
                MenuName7={"Close Left Tab"}
                icons7={<LuDelete className="text-white" />}
                handleClick7={() => handleCloseLeftTabs(index)}
                className7="text-white"
                Menu8
                MenuName8={"Close All Tab"}
                icons8={<MdOutlineDelete className="text-white" />}
                handleClick8={() => handleDeleteAllTab()}
                className8="text-white"
            />
        </div>
    );
};

export default DraggableTab;
